import CheckWebpFeature from "./src/components/common/ggfx-client/module/webp-checker";
CheckWebpFeature();

// export const onInitialClientRender = () => {
//   window.addEventListener('popstate', () =>
//     window.location.href = window.location.href
//   )
// }

export { wrapRootElement } from "./src/apollo/wrap-root-element";

export const disableCorePrefetching = () => true

export const onRouteUpdate = ({ location, prevLocation }) => {
  // console.log('new pathname', location.pathname)
  // console.log('old pathname', prevLocation ? prevLocation.pathname : null)
  if (prevLocation && (prevLocation.pathname == '/' || prevLocation.pathname == '/buy-property/' || prevLocation.pathname == '/rent/' || prevLocation.pathname.indexOf("property-for-sale") >= 0 || prevLocation.pathname.indexOf("property-to-rent") >= 0)) {
    if (window)
      window.scroll({
        top: -100,
        behavior: 'smooth'
      });
  }
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {

  // Avoid to scroll to top when next page trigger click
  // But this feature should condiser with details page back scroll too

  if (location.pathname.match(/property\/for-sale/)) {
    return false;
  }
  // if (location.pathname.match(/property-for-sale/)) {
  //   return false;
  // }
  // if (location.pathname.match(/property-to-rent/)) {
  //   return false;
  // }
  // if (location.pathname.match(/new-home-for-sale/)) {
  //   return false;
  // }
  if (location.pathname.match(/property\/to-rent/)) {
    return false;
  }
  if (location.pathname.match(/property\/new-homes\/for-sale/)) {
    return false;
  }


  return true;
};
